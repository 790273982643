const computedStyle = require('../utils/computedStyle');

export default class ScrollTopClass {
  constructor() {
    this.content = $('.footer-item--top');
    this.document = $('html, body');
    this.speed = '300';
    this.breakpoints = parseInt(computedStyle.default.get('--breakpoint-xl'));
    this.event = $(window).innerWidth() <= this.breakpoints ? 'touchstart' : 'click';
  }

  init() {
    this.handlerScollTo();
  }

  handlerScollTo() {
    this.content.on(this.event, (e) => {
      e.preventDefault();
      this.document.animate({ scrollTop: 0 }, this.speed);
    });
  }
}
