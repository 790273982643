import Menu from '@assets/components/menu';

export default class extends Menu {
  constructor() {
    super();
    this.target = document.getElementsByTagName('header')[0];
    this.$menuArea = $('.main-menu__area');
    this.$navbarToggler = $('.navbar__toggler');
    this.$verticalMenu = $('.main-navigation__menu--vertical');
    this.$firstLevelNext = $('.main-menu__next');
    this.$secondLevelNext = $('.main-menu__next--submenu');
    this.$menuListPanel = $('.main-menu__list--panel');
    this.$back = $('.main-menu__item--back');
    this.$item = '.main-menu__item';
    this.$itemActive = '.main-menu__item.active';
    this.$actionUser = $('.navbar__action-user');
    this.$userPopoverNav = $('.navbar__action-user .nav');
    this.$suggestionsWarpper = $('.suggestions-wrapper');
    this.$modalBackground = $('.background');
    this.$submenuLink = $('.main-navigation-submenu-content-row .col-3 ul li a');
  }

  init() {
    super.init();
    this.getHeaderHeight();
    this.handlerVerticalMenu();
    this.handlerFirstLevelHover();
    this.handlerSecondLevelHover();
    this.handlerFirstLevelMenuContent();
    this.handlerSecondLevelMenuContent();
    this.handleUserPopover();
    this.stickyOnScroll();
  }

  getHeaderHeight() {
    if (this.target) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach(() => {
          this.getHeaderComputedStyle();
        });
      });

      observer.observe(this.target, {
        attributes: true,
        childList: true,
        subtree: true
      });

      this.getHeaderComputedStyle();
      window.addEventListener('resize', () => {
        this.getHeaderComputedStyle();
      });
    }
  }

  getHeaderComputedStyle() {
    const style = window.getComputedStyle(this.target);
    const height = style.getPropertyValue('height');
    return document.documentElement.style.setProperty('--header-height', height);
  }

  handlerVerticalMenu() {
    this.$navbarToggler.on('click', (e) => {
      const $this = $(e.currentTarget);
      $this.toggleClass('open');

      if ($this.hasClass('open')) {
        this.$verticalMenu.addClass('is-open');
        this.$modalBackground.addClass('active');
        this.$suggestionsWarpper.removeClass('open');
        $('body').css('overflow', 'hidden');
        this.handlerFirstLevelItem();
        this.handlerSecondLevelItem();
        this.handlerBackLevel();
      } else {
        this.$verticalMenu.removeClass('is-open');
        this.$modalBackground.removeClass('active');
        $('body').css('overflow', '');
      }
    });
  }

  handlerFirstLevelItem() {
    this.$firstLevelNext.on('click', (e) => {
      const $this = $(e.currentTarget);
      const $yoursFirstLevelItem = $this.parents('.main-menu__item');

      $yoursFirstLevelItem.addClass('active');
    });
  }

  handlerSecondLevelItem() {
    this.$secondLevelNext.on('click', (e) => {
      const $this = $(e.currentTarget);
      const $yoursSecondLevelItem = $this.parents('.main-menu__item');

      $yoursSecondLevelItem.addClass('active');
    });
  }

  handlerBackLevel() {
    this.$back.on('click', (e) => {
      const $this = $(e.currentTarget);
      const isItemActive = $this.parents(this.$item).hasClass('active');

      if (isItemActive) {
        $this.parents(this.$itemActive).eq(0).removeClass('active');
      }
    });
  }

  handlerFirstLevelHover() {
    this.$menuHorizontal = $('.main-navigation__menu--horizontal');
    this.$itemLevel = this.$menuHorizontal.find('.main-menu__item');
    this.$menuPremenu = $('.main-navigation__premenu');
    this.$itemAction = $('.navbar__action-page ul li');

    this.$itemLevel
      .on('mouseover', (e) => {
        const isActive = $(e.currentTarget).find('.main-menu__root');
        const $menuList = $(e.currentTarget).children().find('.main-menu__list--list.banner');
        if (isActive.length) {
          this.$modalBackground.addClass('active');
          if ($menuList.attr('data-hero-banner') && $menuList.children().length === 0) {
            $menuList.spinner().start();
            $.ajax({
              url: $menuList.attr('data-hero-banner'),
              type: 'get',
              dataType: 'html',
              success: function (html) {
                $menuList.spinner().stop();
                $menuList.append(html);
              },
              error: function () {
                $menuList.spinner().stop();
              }
            });
          }
        }
      })
      .on('mouseleave', (e) => {
        this.$menuListPanel.removeClass('show');
        this.$menuArea.removeClass('active');

        const isActive = $(e.currentTarget).find('.main-menu__root');
        if (isActive.length) {
          this.$modalBackground.removeClass('active');
        }
      });

    this.$itemAction
      .on('mouseover', (e) => {
        const isActive = $(e.currentTarget).find('.main-navigation-submenu-content');
        if (isActive.length) {
          this.$modalBackground.addClass('active');
        }
      })
      .on('mouseleave', (e) => {
        const isActive = $(e.currentTarget).find('.main-navigation-submenu-content');
        if (isActive.length) {
          this.$modalBackground.removeClass('active');
        }
      });
  }

  handlerSecondLevelHover() {
    let opentimer = 0;
    this.$menuArea
      .on('mouseover', (e) => {
        const $this = $(e.currentTarget);
        const $thisDataArea = $this.data('category');
        const $thisListRoot = $this.parents('.main-menu__root--root').next();
        const $thisYourlist = $thisListRoot.find(`[data-category='${$thisDataArea}']`);
        const $thisYourDatalist = $thisYourlist.data('category');

        opentimer && clearTimeout(opentimer);
        opentimer = null;
        opentimer = setTimeout(() => {
          $this.addClass('active').siblings(this.$menuArea).removeClass('active');
          if ($thisDataArea === $thisYourDatalist) {
            $thisYourlist.addClass('show').siblings().removeClass('show');
          } else {
            $thisListRoot.find('.main-menu__list--list').removeClass('show');
          }
        }, 250);
      })
      .on('mouseleave', () => {
        clearTimeout(opentimer);
        opentimer = null;
      });
  }

  handlerFirstLevelMenuContent() {
    const $menuList = $('.navbar__action-page ul > li');

    $menuList.on('mouseover', function () {
      const $this = $(this);
      const $subMenu = $this.find('.main-navigation-submenu-content');

      if ($this.attr('data-menu-id') && $subMenu.children().length === 0) {
        $subMenu.spinner().start();
        $.ajax({
          url: $this.attr('data-menu-id'),
          type: 'get',
          dataType: 'html',
          success: function (html) {
            $subMenu.spinner().stop();
            $subMenu.append(html);
          },
          error: function () {
            $subMenu.spinner().stop();
          }
        });
      }
    });
  }

  handlerSecondLevelMenuContent() {
    let opentimer = 0;
    $(document)
      .on('mouseover', '.main-navigation-submenu-content-row li', function () {
        const $submenuImg = $('.submenu-banner-content .content-link');
        const $submenuListLinkItem = $('.main-navigation-submenu-content-row .btn-menu-content');
        const content = $(this).attr('data-content-image');

        opentimer && clearTimeout(opentimer);
        opentimer = null;
        opentimer = setTimeout(() => {
          $submenuImg.removeClass('active');
          $submenuListLinkItem.removeClass('active');
          $(`.submenu-banner-content .content-link[data-content-image=${content}]`).addClass('active');
          $(this).find('.btn-menu-content').addClass('active');
        }, 250);
      })
      .on('mouseleave', () => {
        clearTimeout(opentimer);
        opentimer = null;
      });

    $(document).on('mouseleave', '.main-navigation-submenu-content', function () {
      const $submenuImg = $('.submenu-banner-content .content-link');
      const $submenuListLinkItem = $('.main-navigation-submenu-content-row .btn-menu-content');

      $submenuImg.removeClass('active');
      $submenuListLinkItem.removeClass('active');
    });
  }

  handleUserPopover() {
    this.$actionUser
      .on('mouseover focus', () => {
        this.$modalBackground.addClass('active');
        this.$userPopoverNav.attr('aria-hidden', false);
        if (this.$suggestionsWarpper.hasClass('open')) this.$suggestionsWarpper.removeClass('open');
      })
      .on('mouseleave', () => {
        this.$userPopoverNav.attr('aria-hidden', true);
        this.$modalBackground.removeClass('active');
      });
  }

  stickyOnScroll() {
    const target = $('.navbar__logo')[0];

    if (target === undefined) return;

    const callback = function (entries, observer) {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          $('body').addClass('fixed-menu-search');
        } else {
          $('body').removeClass('fixed-menu-search');
        }
      });
    };

    const options = {
      threshold: 0
    };

    const observer = new IntersectionObserver(callback, options);

    observer.observe(target);
  }
}
