export default class booking {
  init() {
    const callback = (mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          $('h2:contains("Chi si desidera incontrare?")').hide();
          $('span:contains("Prossima disponibilità")').hide();
        }
      }
    };
    const modalContentObserver = new MutationObserver(callback);

    $('#booking-modal').on('click', 'button.flow-button__FINISH', function () {
      $(this).closest('#booking-modal').modal('hide');
      modalContentObserver.disconnect();
    });

    $('#booking-modal').on('show.bs.modal', function () {
      const targetNode = $('#booking-modal')[0];
      const config = { attributes: false, childList: true, subtree: true };

      modalContentObserver.observe(targetNode, config);
    });

    $('#booking-modal').on('hide.bs.modal', function () {
      modalContentObserver.disconnect();
    });
  }
}
