import debounce from 'lodash/debounce';

const endpoint = $('.suggestions-wrapper').data('url');
const minChars = 1;
const UP_KEY = 38;
const DOWN_KEY = 40;
const ESC_KEY = 27;
const DIRECTION_DOWN = 1;
const DIRECTION_UP = -1;

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
  return $(scope).closest('form').find('.suggestions-wrapper');
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
  return !!$(scope).closest('.search-mobile').length;
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
  $('body').removeClass('modal-open');
  $('header').siblings().attr('aria-hidden', 'false');
  $('.suggestions').removeClass('modal');
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
  if (isMobileSearch(scope)) {
    $('body').addClass('modal-open');
    $('header').siblings().attr('aria-hidden', 'true');
    getSuggestionsWrapper(scope).find('.suggestions').addClass('modal');
  }
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
  $('input.search-field').val('');
  clearModals();
  $('.search-mobile .suggestions').unbind('scroll');
  $('.suggestions-wrapper').empty();
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
  const mobileSearchIcon = '.search-mobile button.';
  const iconSearch = 'fa-search';
  const iconSearchClose = 'fa-close';

  if (action === 'close') {
    $(mobileSearchIcon + iconSearch).removeClass(iconSearch).addClass(iconSearchClose).attr('type', 'button');
  } else {
    $(mobileSearchIcon + iconSearchClose).removeClass(iconSearchClose).addClass(iconSearch).attr('type', 'submit');
  }
}

/**
 * Determines whether the "More Content Below" icon should be displayed
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(scope) {
  if (($(scope).scrollTop() + $(scope).innerHeight()) >= $(scope)[0].scrollHeight) {
    $('.more-below').fadeOut();
  } else {
    $('.more-below').fadeIn();
  }
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
  let outerHeight;
  let $scope;
  let $suggestions;
  let top;

  if (isMobileSearch(scope)) {
    $scope = $(scope);
    top = $scope.offset().top;
    outerHeight = $scope.outerHeight();
    $suggestions = getSuggestionsWrapper(scope).find('.suggestions');
    $suggestions.css('top', top + outerHeight);

    handleMoreContentBelowIcon(scope);

    // Unfortunately, we have to bind this dynamically, as the live scroll event was not
    // properly detecting dynamic suggestions element's scroll event
    $suggestions.scroll(function () {
      handleMoreContentBelowIcon(this);
    });
  }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
  const $suggestionsWrapper = getSuggestionsWrapper(this).empty();

  $('.site-search .form-group').spinner().stop();

  if (typeof (response) !== 'object') {
    $suggestionsWrapper.append(response).addClass('open');
    $('.background').addClass('active');
    $(this).siblings('.reset-button').addClass('d-sm-block');
    positionSuggestions(this);

    if (isMobileSearch(this)) {
      toggleSuggestionsIcon('close');
      applyModals(this);
    }

    // Trigger screen reader by setting aria-describedby with the new suggestion message.
    const suggestionsList = $('.suggestions .item');
    if ($(suggestionsList).length) {
      $('input.search-field').attr('aria-describedby', 'search-result-count');
    } else {
      $('input.search-field').removeAttr('aria-describedby');
    }
  } else {
    $('.background').removeClass('active');
    $suggestionsWrapper.hide();
  }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
  if ($(scope).val().length >= minChars) {
    const $input = $('.site-search .form-group');
    if ($input.find('.veil').length === 0) $input.spinner().start();
    $.ajax({
      context: scope,
      url: endpoint + encodeURIComponent($(scope).val()),
      method: 'GET',
      success: processResponse,
      error() {
        $input.spinner().stop();
        $('.background').removeClass('active');
      }
    });
  } else {
    toggleSuggestionsIcon('search');
    $(scope).siblings('.reset-button').removeClass('d-sm-block');
    clearModals();
    getSuggestionsWrapper(scope).removeClass('open').empty();
    $('.background').removeClass('active');
  }
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(direction) {
  // get all li elements in the suggestions list
  const suggestionsList = $('.suggestions .item');
  if (suggestionsList.filter('.selected').length === 0) {
    suggestionsList.first().addClass('selected');
    $('input.search-field').each(function () {
      $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
    });
  } else {
    suggestionsList.each(function (index) {
      const idx = index + direction;
      if ($(this).hasClass('selected')) {
        $(this).removeClass('selected');
        $(this).removeAttr('aria-selected');
        if (suggestionsList.eq(idx).length !== 0) {
          suggestionsList.eq(idx).addClass('selected');
          suggestionsList.eq(idx).attr('aria-selected', true);
          $(this).removeProp('aria-selected');
          $('input.search-field').each(function () {
            $(this).attr('aria-activedescendant', suggestionsList.eq(idx)[0].id);
          });
        } else {
          suggestionsList.first().addClass('selected');
          suggestionsList.first().attr('aria-selected', true);
          $('input.search-field').each(function () {
            $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
          });
        }
        return false;
      }
      return true;
    });
  }
}

export default class {
  init() {
    $('form[name="simpleSearch"]').on('submit', (e) => {
      const value = $('input.search-field').val();

      if(!value || value.length < 1) {
        return false;
      } else {
        const suggestionsList = $('.suggestions .item');
        if (suggestionsList.filter('.selected').length !== 0) {
          e.preventDefault();
          suggestionsList.filter('.selected').find('a')[0].click();
        }
      }
    });

    $('input.search-field').each(function () {
      /**
       * Use debounce to avoid making an Ajax call on every single key press by waiting a few
       * hundred milliseconds before making the request. Without debounce, the user sees the
       * browser blink with every key press.
       */
      const debounceSuggestions = debounce(getSuggestions, 300);
      const $toggler = $('.navbar__toggler');
      const $this = $(this);

      $this.on('input focus', function (e) {
        debounceSuggestions(this, e);
        document.documentElement.style.setProperty('--suggestions-offset-top', `${$this[0].getBoundingClientRect().top + $this.height()}px`);

        if ($toggler.hasClass('open')) {
          $toggler.trigger('click');
        }
      }).on('keyup', function(e) {
        // Capture Down/Up/Esc Key Events
        switch (e.which) {
          case DOWN_KEY:
            handleArrow(DIRECTION_DOWN);
            break;
          case UP_KEY:
            handleArrow(DIRECTION_UP);
            break;
          case ESC_KEY:
            $('.suggestions-wrapper').removeClass('open');
            $('.background').removeClass('active');
            break;
          default:
            e.preventDefault();
        }
      });
    });

    $('body').on('click', (e) => {
      if (
        !$('.suggestions, .popover-minicart, .navbar__action-user, .navbar__toggler, [class^="main-navigation__menu"], .main-navigation-submenu-content, .navbar__action-page').has(e.target).length &&
        !$(e.target).hasClass('search-field')
      ) {
        $('.suggestions-wrapper').removeClass('open');
        if (!$('.navbar__toggler').hasClass('open')) $('.background').removeClass('active');
      }
    });

    $('body').on('click', '.search-mobile button.fa-close', (e) => {
      e.preventDefault();
      $('.suggestions-wrapper').removeClass('open');
      $('.background').removeClass('active');
      toggleSuggestionsIcon('search');
      tearDownSuggestions();
    });

    $('.site-search .reset-button').on('click', function () {
      $(this).removeClass('d-sm-block');
    });
  }
}
