const format = {
  numberFormat: (value, styleCurrency, currency, locale) => {
    return new Intl.NumberFormat(locale.replace('_', '-'), {
      style: styleCurrency,
      currency: currency,
      minimumFractionDigits: 2
    })
      .format(value)
      .replace(/\s+/g, '');
  },
  convert: (value) => {
    if (value) {
      const val = value.substring(1).replace(/\s/g, '');
      return parseFloat(val.replace(',', '.'));
    }
  }
};

export default format;
