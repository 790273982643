export default class Tooltip {
    init() {
        $('[data-toggle="tooltip"]').tooltip();

        $('.info-icon').on('mouseenter focusin', function () {
            $(this).find('.tooltip').removeClass('d-none');
        });

        $('.info-icon').on('mouseleave focusout', function () {
            $(this).find('.tooltip').addClass('d-none');
        });
    }
}
