export default class Newsletter {
  constructor() {
    this.$form = $('form.newsletter-us');
    this.$button = $('.button-newsletter');
  }

  init() {
    this.subscribeNewsletter();
    this.clearEvents();
  }

  subscribeNewsletter() {
    this.$form.on('submit', (e) => {
      e.preventDefault();
      const $form = $(e.currentTarget);
      const isWizardForm = $form.attr('id') === 'newsletter-wizard-form';
      const dog = isWizardForm ? 'true' : $form.find('#dog').is(':checked').toString();
      const cat = isWizardForm ? 'true' : $form.find('#cat').is(':checked').toString();
      const aquariology = isWizardForm ? 'true' : $form.find('#aquariology').is(':checked').toString();
      const birds = isWizardForm ? 'true' : $form.find('#birds').is(':checked').toString();
      const rodent = isWizardForm ? 'true' : $form.find('#rodent').is(':checked').toString();
      const privacy = $form.find('#privacy').is(':checked').toString();
      const email = $form.find("[name='hpEmailSignUp']").val();
      const url = $form.attr('action');
      const $container = $form.parents('.modal-content');

      const viewData = {
        dog: dog,
        cat: cat,
        aquariology: aquariology,
        birds: birds,
        rodent: rodent,
        privacy: privacy,
        email: email,
        token: null
      };

      this.$form.spinner().start();
      grecaptcha
        .execute($form.find('.g-recaptcha').data('data-sitekey'), {
          action: 'submit'
        })
        .then((token) => {
          const tokenInput = $('#newsletter-token');
          tokenInput.val(token);
          viewData.token = token;

          $.ajax({
            url: url,
            type: 'POST',
            data: jQuery.param(viewData),
            success: (data) => {
              this.$form.spinner().stop();

              const $title = $container.find('#newsletter-title');
              $title.text(data.msg);

              if (!data.error) {
                $($form).trigger('reset');
              }
            },
            error: () => {
              this.$form.spinner().stop();
            }
          });
        })
        .catch(() => {
          this.$form.spinner().stop();
        });
    });
  }

  clearEvents() {
    const $modal = $('#newsletter-modal');
    $modal.on('hidden.bs.modal', () => {
      const text = $('#newsletter-title').attr('data-initial-text');
      $('#newsletter-title').text(text);
      $('form.newsletter-us').trigger('reset');
      $modal.find('.g-recaptcha').addClass('d-none').removeClass('d-block');
    });

    $modal.on('shown.bs.modal', function () {
      $modal.find('.g-recaptcha').addClass('d-block').removeClass('d-none');
    });
  }
}
