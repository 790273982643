import Accordion from 'accordion/src/accordion.mjs';

export default class AccordionClass {
  init(element) {
    if (element) {
      try {
        const $element = $(element).toArray();
        for (const el of $element) {
          // eslint-disable-next-line no-new
          new Accordion(el, []);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }
}
