import ProductBase from '../product/base';
import focusHelper from '../components/focus';
import { visibleQuantityProductTile } from '../utils/utils';
import listener from '@main/utils/listener';

const base = new ProductBase();
base.visibleQuantityProductTile = visibleQuantityProductTile();

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    let newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            const errorHtml = `${'<div class="alert alert-danger alert-dismissible valid-cart-error '
                + 'fade show" role="alert">'
                + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
                + '<span aria-hidden="true">&times;</span>'
                + '</button>'}${data.valid.message}</div>`;

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append(`${'<div class="row"> '
                + '<div class="col-12 text-center"> '
                + '<h1 class="h2 text-uppercase">'}${data.resources.emptyCartMsg}</h1> `
                + '</div> '
                + '</div>');
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-subtotal').attr('value', data.totals.subTotal.formatted);
    $('.minicart-link').attr({
      'aria-label': data.resources.minicartCountOfItems,
      title: data.resources.minicartCountOfItems
    });

    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost.formatted);
    $('.sub-total-incl-discount').empty().append(data.totals.subTotalIncludingOrderDiscount.formatted);

    // update this rows not on receipt or order detail page
    $('.tax-total:not(.taxt-total-receipt)').empty().append(data.totals.totalTax.formatted);
    $('.sub-total:not(.sub-total-receipt)').empty().append(data.totals.subTotal.formatted);
    $('.grand-total:not(.leading-lines)').empty().append(data.totals.grandTotal.formatted);

    // update this rows not on receipt or order detail page
    if (data.totals.orderLevelDiscountTotal.value > 0) {
      $('.order-discount:not(.leading-lines)').removeClass('hide-order-discount');
      $('.order-discount-total:not(.order-discount-total-receipt)')
        .empty()
        .append(`- ${data.totals.orderLevelDiscountTotal.formatted}`);
    } else {
      $('.order-discount:not(.leading-lines)').addClass('hide-order-discount');
    }

    // update this rows not on receipt or order detail page
    if (data.totals.shippingLevelDiscountTotal.value > 0) {
      $('.shipping-discount:not(.leading-lines)').removeClass('hide-shipping-discount');
      $('.shipping-discount-total:not(.shipping-discount-total-receipt)')
        .empty()
        .append(`- ${data.totals.shippingLevelDiscountTotal.formatted}`);
    } else {
      $('.shipping-discount:not(.leading-lines)').addClass('hide-shipping-discount');
    }

    data.items.forEach((item) => {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $(`.item-${item.UUID}`).empty().append(item.renderedPromotions);
        } else {
            $(`.item-${item.UUID}`).empty();
        }
        $(`.uuid-${item.UUID} .unit-price`).empty().append(item.renderedPrice);
        $(`.line-item-price-${item.UUID} .unit-price`).empty().append(item.renderedPrice);
        $(`.item-total-${item.UUID}`).empty().append(item.priceTotal.renderedPrice);
    });

    if (data.totals.totalShippingCost.value > 0) {
        $('.free-shipping-row').addClass('d-none');
    } else {
        $('.free-shipping-row').removeClass('d-none');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    const errorHtml = `${'<div class="alert alert-danger alert-dismissible valid-cart-error '
        + 'fade show" role="alert">'
        + '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'
        + '<span aria-hidden="true">&times;</span>'
        + '</button>'}${message}</div>`;

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    let html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach((item) => {
            html += `<div class="single-approaching-discount alert alert-info text-center">${
                item.discountMsg}</div>`;
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    let lineItem;
    let messages = '';

    for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $(`.availability-${lineItem.UUID}`).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach((message) => {
                    messages += `<p class="line-item-attributes" style="background-color:${message.color}">${message.msg}</p>`;
                });
            }
        }

        $(`.availability-${lineItem.UUID}`).html(messages);
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) { // eslint-disable-line no-unused-vars
    for (let i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $(`.card.product-info.uuid-${uuid}`).replaceWith(data.renderedTemplate);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    const htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">'
        + '<span class="enter-message sr-only" ></span>'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    const $html = $('<div>').append($.parseHTML(html));

    const body = $html.find('.product-quickview');
    const footer = $html.find('.modal-footer').children();

    return { body, footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success(data) {
            const parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $('body').trigger('editproductmodal:ready');
            $.spinner().stop();
        },
        error() {
            $.spinner().stop();
        },
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    const $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    const $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName);
}

/**
 * Handles the visibility of the titles for single purchases and sor purchases
 * @param {Array} items - the productLineItems in the basket
 */
function handlePurchaseTypeTitles(items) {
    const $purchaseTypesTitles = $('.productlines-header');
    // Hide all titles
    $purchaseTypesTitles.addClass('d-none');

    // Show only the titles for the purchase types actually in the baskets
    items.forEach((item) => {
        if (item.SorPeriodicity && (item.SorWeekInterval || item.SorMonthInterval)) {
            $purchaseTypesTitles.not('.single-purchase').removeClass('d-none');
        } else {
            $purchaseTypesTitles.filter('.single-purchase').removeClass('d-none');
        }
    });
}

/**
 * verify sor product
 * @param {Object} items - basket items
 */
function checkSorProductToActivate(basketItems) {
  const subscriptionBanner = document.getElementById('cart-subscription-banner-container');

  if (!subscriptionBanner) return;

  const sorProducts = basketItems.filter((item) => item.sorProduct && !item.hasSmartOrderRefill);

  if (sorProducts.length) {
    subscriptionBanner.classList.remove('d-none');
  } else {
    subscriptionBanner.classList.add('d-none');
  }
}

export default class {
    init() {
        const _this = this;

        $('body').on('click', '.remove-product', function (e) {
            e.preventDefault();

            const actionUrl = $(this).data('action');
            const productID = $(this).data('pid');
            const productName = $(this).data('name');
            const uuid = $(this).data('uuid');
            confirmDelete(actionUrl, productID, productName, uuid);
        });

        $('body').on('afterRemoveFromCart', (e, data) => {
            e.preventDefault();
            confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
        });

        $('.optional-promo').click((e) => {
            e.preventDefault();
            $('.promo-code-form').toggle();
        });

        $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
            e.preventDefault();

            const $this = $(this);
            const productID = $this.data('pid');
            let url = $this.data('action');
            const uuid = $this.data('uuid');

            _this.removeProductLineItemHandler(productID, url, uuid);
        });

        $('body').on('change', '.quantity-form > .quantity', function () {
            const $this = $(this);
            const quantity = $this.val();
            const productID = $this.data('pid');
            const uuid = $this.data('uuid');

            if (base.visibleQuantityProductTile && $this.hasClass('input-quantity__expereince') && quantity === '0') {
              _this.removeProductLineItemHandler(productID, $this.data('remove-action'), uuid);
              return;
            }

            const preSelectQty = $this.data('pre-select-qty');
            let url = $this.data('action');

            const urlParams = {
                pid: productID,
                quantity,
                uuid,
            };

            url = appendToUrl(url, urlParams);

            $this.parents('.card').spinner().start();

            $('body').trigger('cart:beforeUpdate');

            $.ajax({
                url,
                type: 'get',
                context: this,
                dataType: 'json',
                success(data) {
                  $('.valid-cart-error').remove();
                  if(data.error) {
                    $(`.quantity[data-uuid="${uuid}"]`).val(parseInt(preSelectQty, 10));
                    createErrorNotification(data.errorMessage);
                  } else {
                    $(`.quantity[data-uuid="${uuid}"]`).val(quantity);
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    updateAvailability(data, uuid);
                    validateBasket(data);
                    $this.data('pre-select-qty', quantity);

                    $('body').trigger('cart:update', data);

                    if ($this.parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                        window.location.reload();
                    }

                    if (base.visibleQuantityProductTile) {
                      _this.toggleExperienceQuantity(quantity, productID);
                    }
                  }
                  $.spinner().stop();
                },
                error(err) {
                    $('.valid-cart-error').remove();
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $(`.quantity[data-uuid="${uuid}"]`).val(parseInt(preSelectQty, 10));
                        $.spinner().stop();
                    }
                },
            });
        });

        $('.shippingMethods').change(function () {
            const url = $(this).attr('data-actionUrl');
            const urlParams = {
                methodID: $(this).find(':selected').attr('data-shipping-id'),
            };
            // url = appendToUrl(url, urlParams);

            $('.totals').spinner().start();
            $('body').trigger('cart:beforeShippingMethodSelected');
            $.ajax({
                url,
                type: 'post',
                dataType: 'json',
                data: urlParams,
                success(data) {
                    if (data.error) {
                        window.location.href = data.redirectUrl;
                    } else {
                        $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                        updateCartTotals(data);
                        updateApproachingDiscounts(data.approachingDiscounts);
                        validateBasket(data);
                    }

                    $('body').trigger('cart:shippingMethodSelected', data);
                    $.spinner().stop();
                },
                error(err) {
                    if (err.redirectUrl) {
                        window.location.href = err.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                },
            });
        });

        $('.promo-code-form').submit((e) => {
            e.preventDefault();
            $.spinner().start();
            $('.coupon-missing-error').hide();
            $('.coupon-error-message').empty();
            if (!$('.coupon-code-field').val()) {
                $('.promo-code-form .form-control').addClass('is-invalid');
                $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
                $('.coupon-missing-error').show();
                $.spinner().stop();
                return false;
            }
            const $form = $('.promo-code-form');
            $('.promo-code-form .form-control').removeClass('is-invalid');
            $('.coupon-error-message').empty();
            $('body').trigger('promotion:beforeUpdate');

            $.ajax({
                url: $form.attr('action'),
                type: 'GET',
                dataType: 'json',
                data: $form.serialize(),
                success(data) {
                    if (data.error) {
                        $('.promo-code-form .form-control').addClass('is-invalid');
                        $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                        $('.coupon-error-message').empty().append(data.errorMessage);
                        $('body').trigger('promotion:error', data);
                    } else {
                        $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                        updateCartTotals(data);
                        updateApproachingDiscounts(data.approachingDiscounts);
                        validateBasket(data);
                        $('body').trigger('promotion:success', data);
                    }
                    $('.coupon-code-field').val('');
                    $.spinner().stop();
                },
                error(err) {
                    $('body').trigger('promotion:error', err);
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.errorMessage);
                        $.spinner().stop();
                    }
                },
            });
            return false;
        });

        $('body').on('click', '.remove-coupon', function (e) {
            e.preventDefault();

            const couponCode = $(this).data('code');
            const uuid = $(this).data('uuid');
            const $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
            const $productToRemoveSpan = $('.coupon-to-remove');

            $deleteConfirmBtn.data('uuid', uuid);
            $deleteConfirmBtn.data('code', couponCode);

            $productToRemoveSpan.empty().append(couponCode);
        });

        $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
            e.preventDefault();

            let url = $(this).data('action');
            const uuid = $(this).data('uuid');
            const couponCode = $(this).data('code');
            const urlParams = {
                code: couponCode,
                uuid,
            };

            url = appendToUrl(url, urlParams);

            $('body > .modal-backdrop').remove();

            $.spinner().start();
            $('body').trigger('promotion:beforeUpdate');
            $.ajax({
                url,
                type: 'get',
                dataType: 'json',
                success(data) {
                    $(`.coupon-uuid-${uuid}`).remove();
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $.spinner().stop();
                    $('body').trigger('promotion:success', data);
                },
                error(err) {
                    $('body').trigger('promotion:error', err);
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                },
            });
        });
        $('body').on('click', '.cart-page .bonus-product-button', function () {
            $.spinner().start();
            $(this).addClass('launched-modal');
            $.ajax({
                url: $(this).data('url'),
                method: 'GET',
                dataType: 'json',
                success(data) {
                    base.methods.editBonusProducts(data);
                    $.spinner().stop();
                },
                error() {
                    $.spinner().stop();
                },
            });
        });

        $('body').on('hidden.bs.modal', '#chooseBonusProductModal', () => {
            $('#chooseBonusProductModal').remove();
            $('.modal-backdrop').remove();
            $('body').removeClass('modal-open');

            if ($('.cart-page').length) {
                $('.launched-modal .btn-outline-primary').trigger('focus');
                $('.launched-modal').removeClass('launched-modal');
            } else {
                $('.product-detail .add-to-cart').focus();
            }
        });

        $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
            e.preventDefault();

            const editProductUrl = $(this).attr('href');
            getModalHtmlElement();
            fillModalElement(editProductUrl);
        });

        $('body').on('shown.bs.modal', '#editProductModal', () => {
            $('#editProductModal').siblings().attr('aria-hidden', 'true');
            $('#editProductModal .close').focus();
        });

        $('body').on('hidden.bs.modal', '#editProductModal', () => {
            $('#editProductModal').siblings().attr('aria-hidden', 'false');
        });

        $('body').on('keydown', '#editProductModal', (e) => {
            const focusParams = {
                event: e,
                containerSelector: '#editProductModal',
                firstElementSelector: '.close',
                lastElementSelector: '.update-cart-product-global',
                nextToLastElementSelector: '.modal-footer .quantity-select',
            };
            focusHelper.setTabNextFocus(focusParams);
        });

        $('body').on('product:updateAddToCart', (e, response) => {
        // update global add to cart (single products, bundles)
            const dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');

            $('.update-cart-product-global', dialog).attr('disabled',
                !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available'));
        });

        $('body').on('product:updateAvailability', (e, response) => {
        // bundle individual products
            $('.product-availability', response.$productContainer)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);

            const dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');

            if ($('.product-availability', dialog).length) {
            // bundle all products
                const allAvailable = $('.product-availability', dialog).toArray()
                    .every((item) => $(item).data('available'));

                const allReady = $('.product-availability', dialog).toArray()
                    .every((item) => $(item).data('ready-to-order'));

                $('.global-availability', dialog)
                    .data('ready-to-order', allReady)
                    .data('available', allAvailable);

                $('.global-availability .availability-msg', dialog).empty()
                    .html(allReady ? response.message : response.resources.info_selectforstock);
            } else {
            // single product
                $('.global-availability', dialog)
                    .data('ready-to-order', response.product.readyToOrder)
                    .data('available', response.product.available)
                    .find('.availability-msg')
                    .empty()
                    .html(response.message);
            }
        });

        $('body').on('product:afterAttributeSelect', (e, response) => {
            if ($('.modal.show .product-quickview .bundle-items').length) {
                $('.modal.show').find(response.container).data('pid', response.data.product.id);
                $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
            } else {
                $('.modal.show .product-quickview').data('pid', response.data.product.id);
            }
        });

        $('body').on('change', '.quantity-select', function () {
            const selectedQuantity = $(this).val();
            $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
        });

        $('body').on('change', '.options-select', function () {
            const selectedOptionValueId = $(this).children('option:selected').data('value-id');
            $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
        });

        base.selectAttribute();
        base.colorAttribute();
        base.removeBonusProduct();
        base.selectBonusProduct();
        base.enableBonusProductSelection();
        base.showMoreBonusProducts();
        base.addBonusProductsToCart();
        base.focusChooseBonusProductModal();
        base.trapChooseBonusProductModalFocus();
        base.onClosingChooseBonusProductModal();
        base.addToCart();
    }

    removeProductLineItemHandler(productID, actionUrl, uuid) {
      const urlParams = {
          pid: productID,
          uuid,
      };
      const url = appendToUrl(actionUrl, urlParams);

      $('body > .modal-backdrop').remove();

      $.spinner().start();

      $('body').trigger('cart:beforeUpdate');

      $.ajax({
          url,
          type: 'get',
          dataType: 'json',
          success(data) {
              const $wrapper = $(`.remove-product[data-pid="${data.productID}"]`).first();
              const $quantity = $(`.quantity-select[data-pid="${data.productID}"]`);

              listener.dataLayerRemoveFromCartHandler($wrapper, $quantity);

              if (data.basket.items.length === 0) {
                  $('body > .background').removeClass('active');
                  $('.cart').empty().append(`${'<div class="row"> '
                  + '<div class="col-12 text-center"> '
                  + '<h1 class="h2 text-uppercase">'}${data.basket.resources.emptyCartMsg}</h1> `
                  + '</div> '
                  + '</div>');
                  $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                  $('.minicart-quantity').empty().append(data.basket.numItems);
                  $('.minicart-subtotal').attr('value', data.basket.numItems);
                  $('.minicart-link').attr({
                      'aria-label': data.basket.resources.minicartCountOfItems
                  });
                  $('.minicart .popover').empty();
                  $('.minicart .popover').removeClass('show');
                  $('body').removeClass('modal-open');
                  $('html').removeClass('veiled');
                  if ($('.cart-recommendations').length > 0) {
                    $('.cart-recommendations').remove();
                  }
              } else {
                  if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                      for (let i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                          $(`.uuid-${data.toBeDeletedUUIDs[i]}`).remove();
                      }
                  }
                  $(`.uuid-${uuid}`).remove();
                  if (!data.basket.hasBonusProduct) {
                      $('.bonus-product').remove();
                  }
                  $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                  updateCartTotals(data.basket);
                  updateApproachingDiscounts(data.basket.approachingDiscounts);
                  handlePurchaseTypeTitles(data.basket.items);
                  $('body').trigger('setShippingMethodSelection', data.basket);
                  validateBasket(data.basket);
                  checkSorProductToActivate(data.basket.items);
              }

              if (base.visibleQuantityProductTile && data.experienceAddToCartTemplate) {
                base.setExperienceAddToCartTemplate(productID, data.experienceAddToCartTemplate);
              }

              $('body').trigger('cart:update', data);

              $.spinner().stop();
          },
          error(err) {
              if (err.responseJSON.redirectUrl) {
                  window.location.href = err.responseJSON.redirectUrl;
              } else {
                  createErrorNotification(err.responseJSON.errorMessage);
                  $.spinner().stop();
              }
          },
      });
    }

    toggleExperienceQuantity(qtyValue, productID) {
      const experienceCounters = document.getElementsByClassName('input-quantity__expereince');
      if (!experienceCounters) return;

      const counterTotal = experienceCounters.length;

      for (let index = 0; index < counterTotal; index++) {
        const counter = experienceCounters[index];
        if (counter.getAttribute('data-pid') === productID) {
          const qtyWrapper = counter.closest('.quantity-wrap__experience');
          const btnAddToCart = qtyWrapper.closest('.add-to-cart-wrapper__experience').querySelector('.add-to-cart__experience');
          const qtyTextElement = counter.closest('.counters').querySelector('.quantity__expereince');
          const qtyLabelSingleProd = qtyTextElement.getAttribute('data-text-one-product');
          const qtyLabelMultipleProds = qtyTextElement.getAttribute('data-text-multiple-products');

          if (qtyValue === 0) {
            qtyWrapper.style.display = 'none';
            btnAddToCart.style.display = 'flex';
          } else {
            qtyWrapper.style.display = 'block';
            btnAddToCart.style.display = 'none';
            qtyTextElement.innerText = `${qtyValue} ${qtyValue > 1 ? qtyLabelMultipleProds : qtyLabelSingleProd}`;
          }
        }
      }
    }
}
