import MiniCart from '@assets/components/miniCart';

const math = require('../utils/math');
const calculate = require('../utils/calculate');
const progress = require('../components/progress');
const actionCart = 'Cart-RemoveProductLineItem';
const actionAddProduct = 'Cart-AddProduct';

export default class extends MiniCart {
  constructor() {
    super();
    this.maxvalue = 200;
    this.input = '.input-quantity';
    this.$miniCart = $('.minicart');
    this.$popover = $('.popover');
  }

  init() {
    super.init();
    this.handlerUpdateQuantity();
    this.handlerUpdateProgress();
  }

  handlerUpdateQuantity() {
    this.$miniCart.on('mouseenter focusin touchstart', () => {
      const obs = new MutationObserver((e) => {
        this.$popover.each((index, element) => {
          const inputCounterMiniCart = this.$popover.find(this.input);

          math.default.update(inputCounterMiniCart, this.maxvalue);
        });
      });

      this.$popover.each((index, element) => {
        obs.observe(element, {
          childList: true,
          subtree: true
        });
      });
    });
  }

  handlerUpdateProgress() {
    $(document).ajaxComplete(function (e, xhr) {
      let existAction;

      if (xhr.responseJSON) {
        existAction = true;
      } else {
        existAction = false;
      }

      if (xhr.status === 200 && xhr.responseJSON !== actionAddProduct && existAction) {
        const httpRequestResponse = xhr.responseText.trim() !== '' ? JSON.parse(xhr.responseText.toString()) : '';

        if (httpRequestResponse.action === actionCart) {
          const $miniCart = $('.minicart .minicart-subtotal');
          const $shippingContainer = $('.free-shipping-container');
          const valueShipping = $shippingContainer.find('.value-shipping').data('value');

          const formatValueSubTotal = parseFloat($miniCart.attr('value').replace(',', '.'));
          const subTotals = calculate.default.progress(formatValueSubTotal, valueShipping);
          const progressFreeShipping = valueShipping - formatValueSubTotal;

          progress.default.build(subTotals, isNaN(progressFreeShipping) ? valueShipping : progressFreeShipping);
        }
      }
    });
  }
}
