import Plyr from 'plyr';

export default class VideoContent {
  init() {
    $('.content-video').on('click', '.player', function () {
      const id = $(this).attr('data-video-id');
      const title = $(this).attr('data-modal-title');

      $('.video-content-media').attr('data-plyr-embed-id', id);
      $('.video-content-media-title').text(title);
      Plyr.setup('.video-content-media');

      $('.video-content-media-modal').on('hidden.bs.modal', function () {
        $('.video-content-media-modal .modal-body').html(
          '<div class="video-content-media" data-plyr-provider="youtube" data-plyr-embed-id=""></div>'
        );
      });
    });
  }
}
