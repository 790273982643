const animation = {
  progress: (progressElement, result, animationLength) => {
    $(progressElement)
      .stop()
      .animate(
        {
          width: result + '%'
        },
        animationLength
      );
  },
  pickUpInStore: (container, methodID, pickUpPoints) => {
    const form = container.find('.shipping-form');
    const $shippingSection = container.find('.shipping-section .shipping-address-block');
    const $shippingSummary = container.find('.shipping-summary');
    const $labelShippingSection = $shippingSection.find('.card-header-custom');
    const $labelShippingSummary = $shippingSummary.find('.shipping-addr-label');
    const shippingTextInitial = $shippingSection.find('.card-header-custom').attr('data-text-initial');
    const shippingTextChanged = $shippingSection.find('.card-header-custom').attr('data-text-changed');

    if (methodID === 'idt-pickup-in-store') {
      $labelShippingSection.text(shippingTextChanged);
      $labelShippingSummary.text(shippingTextChanged);
      pickUpPoints.addClass('is-active');
      $('input[name$=_shippingAddressUseAsBillingAddress]', form).prop('checked', true);
      $('.is-shipping-address-use-as-billing').hide();
      $('body').trigger('checkout:disableButton', '.next-step-button button');

      setTimeout(() => {
        pickUpPoints.addClass('is-visible');
      }, 200);
    } else {
      $labelShippingSection.text(shippingTextInitial);
      $labelShippingSummary.text(shippingTextInitial);
      $('input[name$=_shippingAddressUseAsBillingAddress]', form).prop('checked', false);
      $('.is-shipping-address-use-as-billing').show();
      $('body').trigger('checkout:enableButton', '.next-step-button button');

      setTimeout(() => {
        pickUpPoints.removeClass('is-active');
      }, 500);

      setTimeout(() => {
        pickUpPoints.removeClass('is-visible');
      }, 100);
    }
  },
  webToCase: (data) => {
    if ($('.assistance-messages').length === 0) {
      $('body').append('<div class="assistance-messages"></div>');
    } else {
      $('.assistance-messages').empty();
    }

    $('.assistance-messages').append(
      `<div class="alert${!data.error ? '-success' : '-danger'} assistance-alert text-center" role="alert">${data.msg}</div>`
    );

    setTimeout(() => {
      $('.add-preferred-store-alert').remove();
    }, 2000);
  },
  paymentTab: (listPaymentElement, selectedShippingMethod) => {
    listPaymentElement.each((index, item) => {
      const itemShippingMethodValue = $(item).attr('data-shipping-method-id');

      if (
        itemShippingMethodValue === 'CASH_ON_DELIVERY' &&
        (selectedShippingMethod === 'idt-cash-on-delivery' || selectedShippingMethod === 'idt-cash-on-delivery-isole')
      ) {
        $(item).css('display', 'block');
        $(item).find('a').trigger('click');
      } else if (
        itemShippingMethodValue === 'null' &&
        (selectedShippingMethod !== 'idt-cash-on-delivery' || selectedShippingMethod === 'idt-cash-on-delivery-isole')
      ) {
        $(item).css('display', 'block');
        $(item).find('a').trigger('click');
      } else {
        $(item).css('display', 'none');
      }
    });
  }
};

export default animation;
