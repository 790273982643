const calculate = {
  progress: (progressCart, progressBarWidth) => {
    return (progressCart * 100) / progressBarWidth;
  },
  quantityMultiPack: (quantityFormatted, quantity, counter) => {
    quantityFormatted.attr('value', quantity).val(quantity);
    counter.trigger('change');

    const $decrease = $('.decrease');
    return parseInt(quantity) === 1 ? $decrease.addClass('disabled') : $decrease.removeClass('disabled');
  }
};

export default calculate;
