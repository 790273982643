import Splide from '@splidejs/splide';

export const arrowPath =
  'M14.9994954,33.0005836 C14.7734954,33.0005836 14.5464954,32.9245836 14.3594954,32.7695836 C13.9354954,32.4155836 13.8784954,31.7845836 14.2314954,31.3605836 L23.6984954,19.9985836 L14.2314954,8.64058362 C13.8784954,8.21558362 13.9354954,7.58558362 14.3594954,7.23158362 C14.7844954,6.87758362 15.4134954,6.93658362 15.7684954,7.35958362 L25.7684954,19.3585836 C26.0774954,19.7295836 26.0774954,20.2675836 25.7684954,20.6385836 L15.7684954,32.6405836 C15.5704954,32.8785836 15.2864954,33.0005836 14.9994954,33.0005836';

export default class CarouselClass {
  init() {
    const $elements = $('.splide');

    if ($elements) {
      try {
        $elements.each((index) => {
          new Splide($elements[index], {
            arrowPath: arrowPath
          }).mount();
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }
}
