/**
 * appends params to a url
 * @param {Object} data - data returned from the server's ajax call
 * @param {Object} $button - jquery element button that was clicked to add a product to the wishlist
 */
function displayMessageAndChangeIcon(data, $button) {
  $.spinner().stop();
  $button.removeClass('loading');

  let status;
  if (data.success) {
    status = 'alert-success';
    $('.main-menu__link-wishlist').addClass('filled');
    if (!$button.hasClass('filled')) {
      $button.addClass('filled');
    }
  } else {
    status = 'alert-danger alert-dismissible fade show';
  }

  const close =
    '<button type="button" class="close btn btn-close border-0 p-0" data-dismiss="alert"><span aria-hidden="true">&times;</span></button>';

  if ($('.add-to-wishlist-messages').length === 0) {
    $('body').append('<div class="add-to-wishlist-messages"></div>');
  }

  $('.add-to-wishlist-messages').append(
    `<div class="add-to-wishlist-alert text-center ${status}">${!data.success ? close : ''}${data.msg}</div>`
  );

  if (!data.error) {
    setTimeout(function () {
      $('.add-to-wishlist-messages').remove();
    }, 2000);
  }
}

export default class WishlistProductTile {
  init() {
    this.addToWishlist();
    this.removeDisplayMessage();
  }

  addToWishlist() {
    $('body').on('click', '.wishlist-product-tile', function (e) {
      e.preventDefault();

      const $this = $(this);
      const url = $this.attr('data-href');
      const pid = $this.closest('.product').data('pid') || $this.closest('.product-tile-pd').data('pid');
      const optionId = $this.closest('.product-detail').find('.product-option').attr('data-option-id') || null;
      const optionVal = $this.closest('.product-detail').find('.options-select option:selected').attr('data-value-id') || null;

      if (!url || !pid) {
        return;
      }

      $this.addClass('loading');
      $.spinner().start();
      $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: {
          pid: pid,
          optionId: optionId,
          optionVal: optionVal
        },
        success: function (data) {
          displayMessageAndChangeIcon(data, $this);
        },
        error: function (err) {
          displayMessageAndChangeIcon(err, $this);
        }
      });
    });
  }

  removeDisplayMessage() {
    $(document).on('click', '.add-to-wishlist-messages .close', (e) => {
      const $this = $(e.currentTarget);
      $this
        .parent()
        .addClass('fade-out')
        .on('animationend', () => {
          $this.parent().remove();
        });
    });
  }
}
