export default class HttpReferrerTracking {
  constructor() {
    this.referrer = null;
  }

  init() {
    this.referrer = this.getReferrer();

    if (this.referrer !== null) {
      this.setSessionStorage(JSON.stringify({ hostName: this.referrer, date: new Date() }));
    }
  }

  getReferrer() {
    if (sessionStorage.getItem('httpReferrerData')) return null;

    const referrer = document.referrer;
    const pageUrl = new URL(window.location.href);
    const searchParams = pageUrl.searchParams;

    if (searchParams.get('http_referrer') && searchParams.get('http_referrer') === 'email') {
      return searchParams.get('http_referrer');
    } else if (referrer) {
      const referrerUrl = new URL(referrer);
      if (referrerUrl.hostname !== pageUrl.hostname) {
        return referrerUrl.hostname;
      }
    }

    return null;
  }

  setSessionStorage(data) {
    const referrerObj = sessionStorage.getItem('httpReferrerData');

    if (!referrerObj) {
      sessionStorage.setItem('httpReferrerData', data);
    }
  }

  getSessionStorage() {
    let referrerObj = sessionStorage.getItem('httpReferrerData');
    if (!referrerObj) return null;
    referrerObj = JSON.parse(referrerObj);
    const hostName = referrerObj.hostName;
    const date = referrerObj.date;

    return { hostName, date };
  }

  resetSessionStorage() {
    const referrerObj = sessionStorage.getItem('httpReferrerData');
    if (!referrerObj) return;

    sessionStorage.removeItem('httpReferrerData');
  }
}
