const animation = require('../utils/animation');
const format = require('../utils/format');

const progress = {
  build: (progressTotal, progressFreeShipping) => {
    const $productContainer = $('.product-detail');
    const locale = $productContainer.data('locale');
    const currency = $productContainer.data('currency');
    const $shippingContainer = $('.free-shipping-container');
    const $valueShippingResult = $shippingContainer.find('.value-shipping-result');
    const $shippingResultItem = $shippingContainer.find('.shipping-result-text');
    const $progressElement = $('.progress-value');
    const animationLength = 10;

    let result = progressTotal;
    if (progressTotal < 0 || progressTotal > 100) {
      result = 100;
    }

    if (result > 0) {
      animation.default.progress($progressElement, result, animationLength);
      $progressElement.addClass('is-active');

      if (progressFreeShipping < 0) {
        $shippingResultItem.hide();
      } else {
        $shippingResultItem.show();
      }

      const valueFormat = format.default.numberFormat(progressFreeShipping, 'currency', currency, locale);

      $valueShippingResult.text(valueFormat);
    }
  }
};

export default progress;
