import Splide from '@splidejs/splide';

let selectedAnimal = '';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function isRangeInputValid(inputString, rangeInputRegex = /^[0-9]+(?:[.,][0-9]+)?$/) {
  return rangeInputRegex.test(inputString);
}

function validateMultipleWeights() {
  const adultExpectedWeightContainer = $('.adultExpectedWeight-container');
  const multipleWeightsExpected = adultExpectedWeightContainer.length > 0 && !$('.adultExpectedWeight-container').hasClass('d-none');
  let result = false;
  if (multipleWeightsExpected) {
    // At this point, the actual weight is already valid. The additional weight needs to have a value associated and the validity of this second field needs to be checked with its relation with the former
    const $actualWeight = $('.js-answer-to-question[data-question-topic="weight"]');
    const actualWeightValue = parseFloat($actualWeight.val());
    const $additionalAnswerField = $('.adultExpectedWeight-container').find('.js-wizard-additional-range-value');
    const adultExpectedWeightValue = parseFloat($additionalAnswerField.val());

    if (isRangeInputValid(adultExpectedWeightValue) && adultExpectedWeightValue >= actualWeightValue) {
      const $adultExpectedWeightFieldToPopulate = $('.js-additional-answer-to-question[data-question-topic="adultExpectedWeight"]');
      $adultExpectedWeightFieldToPopulate.val(adultExpectedWeightValue);
      $adultExpectedWeightFieldToPopulate.attr('label', adultExpectedWeightValue + ' kg');
      result = true;
    }
  } else {
    result = true;
  }
  return result;
}

function hideRangeError() {
  $('#adultExpectedWeight-error').hide();
}

function showRangeError() {
  $('#adultExpectedWeight-error').show();
}

/**
 * Hide or show some fields according to the answer provided for the age
 * @param {String} ageValue
 */
function manageAgeEntry(ageValue) {
  if (ageValue === 'puppy') {
    $('.adultExpectedWeight-container').removeClass('d-none');
  } else {
    $('.adultExpectedWeight-container').addClass('d-none');
  }
}

/**
 * Hide or show some fields according to the answer provided for the animalType
 * @param {String} ageValue
 */
function manageAnimalTypeEntry(animalTypeValue) {
  // In all next slides where background images are different between dog and cat, show the correct one
  $('.js-wizard-background-image:not([data-keep-shown]').addClass('d-none');
  $('.js-wizard-background-image-' + animalTypeValue).removeClass('d-none');
  // In the age slide, hide the puppy option (only for the cat, and at the moment)
  if (animalTypeValue === 'cat') {
    $('.js-wizard-radio-value[value=puppy]').parent().addClass('d-none');
  } else {
    $('.js-wizard-radio-value[value=puppy]').parent().removeClass('d-none');
  }
  // In the physical state slide, show only the modal for the selected animal
  $('.js-wizard-physical-state-modal').addClass('d-none');
  $('.js-wizard-physical-state-modal-' + animalTypeValue).removeClass('d-none');
  // In the "flavors" question hide the unwanted flavors according to the preference
  const $flavorsQuestion = $('.js-wizard-flavor');
  const flavorsToShow = $flavorsQuestion.data('flavors-to-show');
  let flavorsToShowObj;
  if (flavorsToShow) {
    flavorsToShowObj = flavorsToShow[animalTypeValue];
  }
  if (flavorsToShowObj) {
    $flavorsQuestion.find('.js-wizard-option').each(function () {
      const value = $(this).val();
      if (!flavorsToShowObj.includes(value)) {
        $(this).parent('.js-button-container').addClass('d-none');
      } else {
        $(this).parent('.js-button-container').removeClass('d-none');
      }
    });
  }
  // In the recap show the icon related to the selected animal
  $('.js-animal-recap-icon').addClass('d-none');
  $('.js-animal-recap-icon').removeClass('d-md-none d-lg-block');
  $('.js-animal-recap-icon-' + animalTypeValue).removeClass('d-none');
  $('.js-animal-recap-icon-' + animalTypeValue).addClass('d-md-none d-lg-block');
  // In the result asset, redirect to the products search for the selected animal
  const $allProductsLink = $('.js-wizard-all-products-link');
  const allProductsLinkBaseHref = $allProductsLink.data('base-href');
  if (allProductsLinkBaseHref) {
    $allProductsLink.attr('href', allProductsLinkBaseHref + ' ' + animalTypeValue);
  }
}

/**
 * Method to enable or disable the button to next step
 * @param {*} $elementFromStep
 * @param {*} handleAction - type of action to perform (either 'enable' or 'disable')
 */
function handleNextQuestionButton($elementFromStep, handleAction) {
  if (handleAction === 'enable') {
    const $currentButton = $elementFromStep.parents('.js-wizard-step-container').find('.js-wizard-next-question');
    $currentButton.removeClass('disabled');
    $currentButton.find('.next-question-disabled-logo').addClass('d-none');
    $currentButton.find('.next-question-enabled-logo').removeClass('d-none');
  } else if (handleAction === 'disable') {
    const $currentButton = $elementFromStep.parents('.js-wizard-step-container').find('.js-wizard-next-question');
    $currentButton.addClass('disabled');
    $currentButton.find('.next-question-enabled-logo').addClass('d-none');
    $currentButton.find('.next-question-disabled-logo').removeClass('d-none');
  }
}

/**
 * Method to prefill a select on landing fetching the values through a custom preference on back-end side
 * @param {*} $wizardStep
 */
function loadPreferenceOptionsAjax($wizardStep) {
  $.spinner().start();
  const questionTopic = $wizardStep.data('question-topic');
  const animalType = $('.js-answer-to-question[data-question-topic="animalType"]').val();

  $.ajax({
    url: $wizardStep.data('load-options-url'),
    type: 'post',
    data: {
      questionTopic: questionTopic,
      animalType: animalType
    },
    dataType: 'json',
    success: function (result) {
      if (result && result.success && result.data) {
        const $selectToFill = $('#wizard-select-' + questionTopic);
        $selectToFill.children('option:not(:first)').remove();
        Object.keys(result.data).forEach((entry) => {
          const entryLabel = capitalizeFirstLetter(entry);
          $selectToFill.append(
            `<option class="js-wizard-option" label="${entryLabel}" value="${result.data[entry]}">${entryLabel}</option>`
          );
        });
      }
      $.spinner().stop();
    },
    error: function () {
      $.spinner().stop();
    }
  });
}

export default class ProductSuggestionWizard {
  init() {
    this.startQuiz();
    this.provideAnswer();
    this.changeRangeValue();
    this.goToPreviousQuestion();
    this.goToNextQuestion();
    this.submitValues();
    this.modalShown();
    this.changeResultView();
  }

  startQuiz() {
    $('body').on('click', '.js-wizard-start-test-button', function () {
      // Hide the intro and show the first question
      $('.js-wizard-intro').addClass('hide-wizard-slide');
      $('.js-wizard-question-1-container').parents('.js-wizard-step-container').removeClass('hide-wizard-slide');
    });
  }

  // Provide an answer to each type of question
  provideAnswer() {
    // Flag the selected option as 'selected'
    $('body').on('click', '.js-wizard-option', function () {
      const $currentQuestion = $(this).parents('.js-wizard-question');
      // Un-select all the options to the current question
      $currentQuestion.find('.js-wizard-option').removeClass('selected');
      $currentQuestion.find('.js-option-image-selected').addClass('d-none');
      $currentQuestion.find('.js-option-image').removeClass('d-none');
      // select the last clicked (changing the images shown) and enable the 'next question' button
      $(this).addClass('selected');
      $(this).find('.js-option-image').addClass('d-none');
      $(this).find('.js-option-image-selected').removeClass('d-none');
      handleNextQuestionButton($(this), 'enable');

      // Finally, if an option is being selected in the last step, enable the 'next question' button to trigger the newsletter modal only if it has not been shown already
      const $currentNavigationButtons = $(this).parents('.js-wizard-step-container').find('.js-wizard-navigation-buttons');
      if (
        $currentNavigationButtons.data('current-step') === $currentNavigationButtons.data('total-number-of-questions') &&
        !$('.js-wizard-go-to-recap').data('modal-shown')
      ) {
        $('.js-wizard-go-to-recap').attr('data-toggle', 'modal');
        $('.js-wizard-go-to-recap').attr('data-target', '#newsletter-wizard-modal');
        $('.js-wizard-go-to-recap').data('modal-shown', true);
      }
    });

    // On text or select-type questions, enable the next step button only if something has been submitted
    $('body').on('input', '.js-wizard-text-value, .js-wizard-select-value', function () {
      if ($(this).val()) {
        handleNextQuestionButton($(this), 'enable');
      } else {
        handleNextQuestionButton($(this), 'disable');
      }
    });
  }

  // Click on either of the +/- values to change the range value by the threshold defined in the ISML as 'stepLength'
  changeRangeValue() {
    $('body').on('click', '.js-wizard-range-button', function () {
      const $relatedValueField = $(this).parents('.js-wizard-range').find('.js-wizard-range-value');
      let currentValue = $relatedValueField.val();

      // The next code takes place only if the input value is valid (it's a number with at most a dot or a comma as separator)
      if (currentValue && isRangeInputValid(currentValue)) {
        currentValue = parseFloat(currentValue.replace(',', '.'));
        const stepLength = $(this).parent().data('step-length');
        // Round manual inputs (such as 0.4) to the closest step-length (in this case 0.5)
        const adjustedValue = Math.round(currentValue / stepLength) * stepLength;

        if ($(this).data('action') === 'increase') {
          // If pressing the '+' button, add the threshold
          $relatedValueField.val(adjustedValue + stepLength);
          $(this).parent().find($('.js-wizard-range-button[data-action=decrease]')).removeClass('disabled');
          handleNextQuestionButton($(this), 'enable');
        } else if ($(this).data('action') === 'decrease' && adjustedValue >= stepLength) {
          // If pressing the '-' button, subtract the threshold
          const newValue = adjustedValue - stepLength;
          $relatedValueField.val(newValue);
          if (newValue === 0) {
            $(this).addClass('disabled');
            handleNextQuestionButton($(this), 'disable');
          } else {
            handleNextQuestionButton($(this), 'enable');
          }
        }
      }
    });

    $('body').on('change', '.js-wizard-range-value', function () {
      const newValue = $(this).val();
      if (!newValue || !isRangeInputValid(newValue)) {
        $(this).parent().find($('.js-wizard-range-button')).addClass('disabled');
        handleNextQuestionButton($(this), 'disable');
      } else {
        $(this).parent().find($('.js-wizard-range-button')).removeClass('disabled');
        if (parseFloat(newValue) === 0) {
          $(this).parent().find($('.js-wizard-range-button[data-action=decrease]')).addClass('disabled');
        } else {
          handleNextQuestionButton($(this), 'enable');
        }
      }
    });
  }

  goToPreviousQuestion() {
    $('body').on('click', '.js-wizard-previous-question', function () {
      const currentStep = parseInt($(this).parents('.js-wizard-navigation-buttons').data('current-step'));
      if (currentStep > 1) {
        $(this).parents('.js-wizard-step-container').addClass('hide-wizard-slide');
        const isRecapPage = $(this).parents('.js-wizard-step-container').find('.js-wizard-recap').length > 0;
        $('.wizard-question-' + (isRecapPage ? currentStep : currentStep - 1))
          .parents('.js-wizard-step-container')
          .removeClass('hide-wizard-slide');
      }
    });
  }

  goToNextQuestion() {
    $('body').on('click', '.js-wizard-next-question', function () {
      const questionTypesWithOptions = ['buttons', 'select', 'radio', 'radio and checkbox'];
      const currentStep = parseInt($(this).parents('.js-wizard-navigation-buttons').data('current-step'));
      const $answerField = $('.answer-to-question-' + currentStep);
      const $selectedOption = $answerField.parent().find('.js-wizard-option.selected, option:selected');
      const $currentRangeValue = $('.js-wizard-range-value');
      const currentQuestionType = $answerField.data('question-type');
      const currentQuestionTopic = $answerField.data('question-topic');
      const $nextQuestion = $('.answer-to-question-' + (currentStep + 1));
      let canProceed = false;

      if (questionTypesWithOptions.indexOf(currentQuestionType) > -1 && $selectedOption.length > 0) {
        // If the question contains a selected option, assign to the hidden input its value/label
        const $answerRelatedInput = $selectedOption.find('input');
        const valueToAssign = $answerRelatedInput.length > 0 ? $answerRelatedInput.val() : $selectedOption.val();
        let labelToAssign = $answerRelatedInput.length > 0 ? $answerRelatedInput.attr('label') : $selectedOption.attr('label');
        if (!labelToAssign.length) {
          labelToAssign = valueToAssign;
        }
        $answerField.val(valueToAssign);
        $answerField.attr('label', labelToAssign);
        if (currentQuestionType.includes('checkbox')) {
          const $additionalAnswerField = $('.additional-answer-to-question-' + currentStep);
          const $additionalCheckboxInput = $selectedOption.parents('.js-wizard-question').find('.js-wizard-checkbox').find('input');
          const additionalValueToAssign = $additionalCheckboxInput && $additionalCheckboxInput[0] && $additionalCheckboxInput[0].checked;
          let additionalLabelToAssign = $additionalCheckboxInput.attr('label');

          if (currentQuestionTopic.includes('gender')) {
            additionalLabelToAssign = $additionalCheckboxInput.data('label-sterilized-' + valueToAssign);
          }
          $additionalAnswerField.val(additionalValueToAssign);
          $additionalAnswerField.attr('label', additionalLabelToAssign);
        }
        if (currentQuestionTopic === 'age') {
          // According to the age entry selected some fields might be hidden or shown
          manageAgeEntry(valueToAssign);
        } else if (currentQuestionTopic === 'animalType') {
          selectedAnimal = valueToAssign;
          manageAnimalTypeEntry(valueToAssign);
        } else if (currentQuestionTopic === 'flavor') {
          const $snackLink = $('.js-wizard-snack-link');
          const snackLinkBaseHref = $snackLink.data('base-href');
          if (snackLinkBaseHref) {
            $snackLink.attr('href', snackLinkBaseHref + ' ' + selectedAnimal + ' ' + valueToAssign);
          }
        }
        canProceed = true;
      } else if (
        currentQuestionType === 'range' &&
        isRangeInputValid($currentRangeValue.val()) &&
        parseFloat($currentRangeValue.val()) > 0
      ) {
        // If the question is a range question, assign to the hidden input its value as both value/label
        const valueToAssign = $currentRangeValue.val().replace(',', '.');
        $answerField.val(valueToAssign);
        $answerField.attr('label', valueToAssign + ' kg');
        const multipleRangeValid = validateMultipleWeights();
        if (multipleRangeValid) {
          hideRangeError();
          canProceed = true;
        } else {
          showRangeError();
        }
      } else if (currentQuestionType === 'text') {
        // If the question is an input question, assign the value
        const answerProvided = $answerField.parent().find('input.js-wizard-text-value').val();
        if (answerProvided) {
          $answerField.val(answerProvided);
          $answerField.attr('label', answerProvided);
          canProceed = true;
        }
      }

      // The next code is processed only if a value can be submitted before going to the next question
      if (canProceed) {
        if ($nextQuestion && $nextQuestion.data('load-options-url')) {
          loadPreferenceOptionsAjax($nextQuestion);
        }
        // Hide the current slide
        $(this).parents('.js-wizard-step-container').addClass('hide-wizard-slide');

        const totalNumberOfQuestions = parseInt($('.js-wizard-navigation-buttons').data('total-number-of-questions'));
        if (currentStep < totalNumberOfQuestions) {
          // If we are in an intermediate step, show the next question
          $('.wizard-question-' + (currentStep + 1))
            .parents('.js-wizard-step-container')
            .removeClass('hide-wizard-slide');
        } else {
          // If the last question is reached, go to the recap and populate the slide with the required data (coming from the input hidden in the different questions and storing the given answers)
          for (let i = 1; i <= totalNumberOfQuestions; i++) {
            const $currentAnswer = $('.answer-to-question-' + i);
            const $additionalAnswer = $('.additional-answer-to-question-' + i);
            $('.js-wizard-recap-field-' + i + '-label').text($currentAnswer.data('question-localized-topic'));
            $('.js-wizard-recap-field-' + i + '-value').text($currentAnswer.attr('label'));

            if ($additionalAnswer && $additionalAnswer.length > 0 && $additionalAnswer.val()) {
              if ($additionalAnswer.data('question-topic') === 'sterilized') {
                if ($additionalAnswer.val() === 'true') {
                  $('.js-wizard-recap-field-' + i + '-value').append(', ' + $additionalAnswer.attr('label'));
                }
              } else {
                $('.js-wizard-additional-answer-' + i).removeClass('d-none');
                $('.js-wizard-recap-field-' + i + '-additional-label').text($additionalAnswer.data('question-localized-topic'));
                $('.js-wizard-recap-field-' + i + '-additional-value').text($additionalAnswer.attr('label'));
              }
            }
          }
          $('.js-wizard-recap').parents('.js-wizard-step-container').removeClass('hide-wizard-slide');
        }
      }
    });
  }

  submitValues() {
    $('body').on('click', '.js-submit-wizard-form', function (e) {
      e.preventDefault();
      $.spinner().start();
      const formId = $('.js-wizard-form').attr('id');
      const data = {
        formId: formId,
        formValues: {}
      };

      $('.js-answer-to-question').map(function () {
        // Get all the form input filled as {topic: <data-question-topic>, value: <value>}
        data.formValues[$(this).data('question-topic')] = $(this).val();
        return null;
      });
      $('.js-additional-answer-to-question').map(function () {
        // Get all the form input filled as {topic: <data-question-topic>, value: <value>}
        data.formValues[$(this).data('question-topic')] = $(this).val();
        return null;
      });

      $.ajax({
        url: $('.js-wizard-form').attr('action'),
        type: 'post',
        data: { wizardForm: JSON.stringify(data) },
        dataType: 'json',
        success: function (result) {
          if (result && result.success) {
            $.spinner().stop();
            result.data.weights.forEach(function (elem, i) {
              $('.js-wizard-result-value-' + i).text(elem);
            });
            if (result.data.mix) {
              $('.js-wizard-mix-feeding').removeClass('d-none');
              $('.js-wizard-result-value-mix-dry').text(result.data.mix.dry.quantity);
              $('.js-wizard-result-value-mix-wet').text(result.data.mix.wet.format);
            }
            result.data.products.forEach(function (elem, i) {
              $('.js-wizard-result-image-' + i).attr('src', elem.image);
              $('.js-wizard-result-name-' + i).text(elem.name);
              $('.js-wizard-result-url-' + i).attr('href', elem.url);
            });
            // Hide the recap
            $('.js-wizard-recap').parents('.js-wizard-step-container').addClass('hide-wizard-slide');
            // Show the result
            $('.js-wizard-result').removeClass('hide-wizard-slide');
          } else {
            $.spinner().stop();
          }
        },
        error: function () {
          $.spinner().stop();
        }
      });
    });

    $('body').on('keydown', '.js-wizard-range-value', function (e) {
      // Prevent submit of the form by pressing 'Enter'
      if (e.key === 'Enter') {
        e.preventDefault();
      }
    });
  }

  modalShown() {
    $('#newsletter-wizard-modal').on('shown.bs.modal', function () {
      // When the modal is shown, prevent further modal popups (if the user goes back and forth)
      $('.js-wizard-go-to-recap').attr('data-toggle', '');
      $('.js-wizard-go-to-recap').attr('data-target', '');
    });
  }

  changeResultView() {
    $('body').on('click', '.js-wizard-result-see-quantities', function (e) {
      $('.js-wizard-result-quantities').removeClass('d-none');
      $('.js-wizard-result-products').addClass('d-none');
      $('.side-image').removeClass('d-none');
    });

    $('body').on('click', '.js-wizard-result-see-products', function (e) {
      $('.js-wizard-result-products').removeClass('d-none');
      $('.js-wizard-result-quantities').addClass('d-none');
      $('.side-image').addClass('d-none');

      const splideCarousel = $('.suggestion-wizard-splide');
      if (splideCarousel.length && !splideCarousel.hasClass('.is-active')) {
        const splide = new Splide('.suggestion-wizard-splide', {
          type: 'slide',
          perPage: 2,
          drag: false,
          gap: 10,
          breakpoints: {
            767: {
              type: 'loop',
              perPage: 1,
              drag: true,
              autoHeight: true
            }
          }
        });

        splide.mount();
      }
    });
  }
}
