import Cart from '../cart/cart';
import { getMinicartDisplayConfigs, setMinicartBodyScrollToEnd } from '../product/base';

let updateMiniCart = true;

export default class MiniCart {
    init() {
        const cart = new Cart();
        cart.init();

        $('.minicart').on('count:update', (event, count) => {
            if (count && $.isNumeric(count.quantityTotal)) {
                $('.minicart .minicart-quantity').text(count.quantityTotal);
                $('.minicart .minicart-subtotal').attr('value', count.cart.totals.subTotal.formatted);
                $('.minicart .minicart-link').attr({
                    'aria-label': count.minicartCountOfItems
                });
            }
        });

        $('.minicart').on('mouseenter focusin', () => {
            if ($('.search:visible').length === 0) {
                return;
            }
            const url = $('.minicart').data('action-url');
            const count = parseInt($('.minicart .minicart-quantity').text(), 10);
            const { showMiniCart, isDesktop } = getMinicartDisplayConfigs();
            const forseMinicartUpdate = showMiniCart && isDesktop || false;

            if (count !== 0 && $('.minicart .popover.show').length === 0) {
                if (!updateMiniCart && !forseMinicartUpdate || !(window.SorUpdateMiniCart ? window.SorUpdateMiniCart : true)) {
                    $('.suggestions-wrapper').removeClass('open');
                    $('.background').addClass('active');
                    $('.minicart .popover').addClass('show');
                    return;
                }

                $('.suggestions-wrapper').removeClass('open');
                $('.background').addClass('active');
                $('.minicart .popover').addClass('show');
                $('.minicart .popover').spinner().start();
                $.get(url, (data) => {
                    $('.minicart .popover').empty();
                    $('.minicart .popover').append(data);
                    updateMiniCart = false;
                    window.SorUpdateMiniCart = false;
                    setMinicartBodyScrollToEnd();
                    $.spinner().stop();
                });
            }
        });
        $('body').on('click', (e) => {
            if ($('.minicart').has(e.target).length <= 0) {
                $('.background').removeClass('show');
                $('.minicart .popover').removeClass('show');
            }
        });
        $('.minicart').on('mouseleave focusout', (event) => {
            if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
                || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
                || $('body').hasClass('modal-open')) {
                event.stopPropagation();
                return;
            }

            if (parseInt($('.minicart-quantity').text(), 10) > 0 && !$('.navbar__toggler').hasClass('open')) {
              $('.background').removeClass('active')
            }
            $('.minicart .popover').removeClass('show');
        });
        $('body').on('change', '.minicart .quantity', function () {
            if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
                window.location.reload();
            }
        });
        $('body').on('product:afterAddToCart', () => {
            updateMiniCart = true;
        });
        $('body').on('cart:update', () => {
            updateMiniCart = true;
        });
    }
}
