const math = require('../utils/math');

export default class quantity {
  constructor() {
    this.input = '.input-quantity';
  }

  init() {
    this.triggerQuantity();
  }

  triggerQuantity() {
    $(document).on('change', this.input, (e) => {
      const $this = $(e.currentTarget);
      if ($this.closest('.add-to-cart-wrapper__experience').length) return;

      math.default.update(this.input, this.maxvalue);
      const valueInput = $this.val();

      const $thisSelect = $this.parents('#quantity').find('.quantity-select');

      $thisSelect.find(`option[value="${valueInput}"]`).prop('selected', true).trigger('change');
    });
  }
}
