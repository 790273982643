export default class AssetScripts {
  init() {
    if ($('.js-scroll-to-info').length > 0) {
      $(document).on('click', '.js-scroll-to-info', function () {
        const faqElementReference = $(this).data('faq-element');
        const faqElement = faqElementReference ? $('#' + faqElementReference) : null;
        if (faqElement) {
          // Scroll to the related faq element
          const headerHeight = $('.main-navigation').height();
          $([document.documentElement, document.body]).animate({ scrollTop: faqElement.offset().top - headerHeight }, 500);
          // Open the related faq element
          const pressEvent = 'ontouchstart' in document.documentElement ? 'touchend' : 'click';
          faqElement.trigger(pressEvent);
          if (pressEvent === 'touchend') {
            faqElement.addClass('highlight');
            setTimeout(function () {
              faqElement.removeClass('highlight');
            }, 10000);
          }
        }
      });
    }
  }
}
