import Accordion from '../../components/accordion';

export default class Footer {
  constructor() {
    this.$jsAccordionClass = '.accordion';
  }

  init() {
    if (this.$jsAccordionClass.length > 0) {
      new Accordion().init(this.$jsAccordionClass);
    }
    this.handlerFlyerModal();
  }

  handlerFlyerModal() {
    $(document).on('click', '.btn-flyer', function () {
      const iframeURL = $(this).attr('data-flyer-url');
      $('#flyer-modal .flyer-iframe').attr('src', iframeURL);
    });
  }
}
