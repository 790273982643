import update from '../utils/update';

const listener = {
  multiPack: (multiPackElement, event, target) => {
    const $multiPackItems = $('.multi-pack-items');

    $(document).on(event, target, (e) => {
      if (multiPackElement) {
        $(multiPackElement).each((index, el) => {
          const thisVal = $(e.currentTarget).val();
          const thisQuantity = $(el).data('quantity').toString();
          let thisQuantitySelected;

          if ($(el).hasClass('is-active')) {
            thisQuantitySelected = $(el).data('quantity');

            if (parseInt(thisVal) < thisQuantitySelected) {
              $(el).removeClass('is-active');
              $(multiPackElement[index - 1]).addClass('is-active');
            }
          }

          if (thisVal === thisQuantity) {
            $(el).addClass('is-active');
            $multiPackItems.not($(el)).removeClass('is-active');
          }
        });
      }

      $('body').on('product:afterAttributeSelect', (e, response) => {
        update.productPriceWithMultiPack(response.data.product);
      });
    });
  },
  sticky: (element, event) => {
    $(window).on(event, () => {
      const $this = $(element);

      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - $this.height()) {
        $this.addClass('is-hide');
      } else {
        if ($this.find('.btn:visible').length === 0) return;
        $this.removeClass('is-hide');
      }
    });
  },
  setStickyDisplay: (element, displayBreakpoint) => {
    if (!element) return;
    element.classList.add('show');
    const page = document.getElementsByClassName('page')[0];
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize && entry.contentBoxSize[0].inlineSize <= displayBreakpoint && window.innerWidth <= displayBreakpoint) {
          const elementHeight = element.getBoundingClientRect().height;
          page.style.setProperty('padding-bottom', `${elementHeight}px`);
        } else {
          page.style.paddingBottom = '';
        }
      }
    });

    resizeObserver.observe(element);
  },
  shippingMethod: (container, elementList, row) => {
    elementList.find('input[type=radio]').each((i, el) => {
      const $this = $(el);
      const methodID = $($this).is(':checked');
      const form = container.find('.shipping-form');

      const $shippingSection = container.find('.shipping-section .shipping-address-block');
      const $shippingSummary = container.find('.shipping-summary');
      const $labelShippingSection = $shippingSection.find('.card-header-custom');
      const $labelShippingSummary = $shippingSummary.find('.shipping-addr-label');
      const shippingTextChanged = $shippingSection.find('.card-header-custom').attr('data-text-changed');

      if (methodID && el.defaultValue === 'idt-pickup-in-store') {
        row.addClass('is-active');
        $labelShippingSection.text(shippingTextChanged);
        $labelShippingSummary.text(shippingTextChanged);
        $('input[name$=_shippingAddressUseAsBillingAddress]', form).prop('checked', true);
        $('body').trigger('checkout:disableButton', '.next-step-button button');

        setTimeout(() => {
          row.addClass('is-visible');
        }, 200);
      }
    });
  },
  paymentTab: (listPaymentElement, selectedShippingMethod) => {
    $(listPaymentElement).addClass('d-none');

    if (selectedShippingMethod === 'idt-cash-on-delivery' || selectedShippingMethod === 'idt-cash-on-delivery-isole') {
      $('[data-shipping-method-id="CASH_ON_DELIVERY"]').removeClass('d-none');
    } else {
      $('[data-shipping-method-id="null"]').removeClass('d-none');
    }

    $(listPaymentElement).not('.d-none').first().find('a').trigger('click');
  },
  dataLayerAddToCartHandler: (category) => {
    $('body').on('product:afterAddToCart', (e, response) => {
      let $wrapper;
      let quantity = 0;
      const $container = $(`.container.product-detail[data-pid="${response.productID}"]`);

      if (!response.error) {
        if ($container.length > 0) {
          $wrapper = $container;
          quantity = parseInt($('.input-quantity').val());
        } else {
          $wrapper = $(`.product[data-pid="${response.productID}"]`).first();
          quantity = 1;
        }

        const dataLayerProduct = {
          name: $wrapper.attr('data-name'),
          id: $wrapper.attr('data-pid'),
          price: $wrapper.attr('data-price'),
          brand: $wrapper.attr('data-brand'),
          quantity: quantity
        };

        for (let i = 0; i < category.length; i++) {
          dataLayerProduct['item_category' + (i > 0 ? i + 1 : '')] = category[i];
        }

        dataLayer.push({
          event: 'addToCart',
          ecommerce: {
            add: {
              products: [dataLayerProduct]
            }
          }
        });
      }
    });
  },
  dataLayerRemoveFromCartHandler: ($wrapper, $quantity) => {
    dataLayer.push({
      event: 'removeFromCart',
      ecommerce: {
        remove: {
          products: [
            {
              name: $wrapper.attr('data-name'),
              id: $wrapper.attr('data-pid'),
              price: $wrapper.attr('data-price'),
              brand: $wrapper.attr('data-brand'),
              category: '',
              quantity: parseInt($quantity.val())
            }
          ]
        }
      }
    });
  },
  dataLayerCheckoutHandler: ($items, event, step) => {
    const products = [];
    $items.each((index, elem) => {
      products.push({
        name: $(elem).attr('data-name'),
        id: $(elem).attr('data-pid'),
        price: $(elem).attr('data-price'),
        brand: $(elem).attr('data-brand'),
        category: $(elem).attr('data-category'),
        quantity: parseInt($(elem).attr('data-quantity'))
      });
    });

    dataLayer.push({
      event: event,
      ecommerce: {
        checkout: {
          actionField: { step: step },
          products: products
        }
      }
    });
  },
  dataLayerCheckoutStepExist: (index) => {
    return dataLayer.some((object) => {
      if (object.ecommerce) {
        return object.ecommerce.checkout.actionField.step === index;
      }

      return false;
    });
  },
  dataLayerPurchaseHandler: ($invoice, $items) => {
    const products = [];
    $items.each((index, elem) => {
      products.push({
        name: $(elem).attr('data-name'),
        id: $(elem).attr('data-pid'),
        price: $(elem).attr('data-price'),
        brand: $(elem).attr('data-brand'),
        category: $(elem).attr('data-category'),
        quantity: parseInt($(elem).attr('data-quantity'))
      });
    });

    dataLayer.push({
      event: 'purchase',
      enhanced_conversion_data: {
        email: $invoice.attr('data-order-email'),
        phone: $invoice.attr('data-order-phone-number')
      },
      ecommerce: {
        purchase: {
          actionField: {
            id: $invoice.attr('data-order-number'),
            revenue: $invoice.attr('data-revenue'),
            tax: $invoice.attr('data-tax'),
            shipping: $invoice.attr('data-shipping-cost'),
            coupon: $invoice.attr('data-coupon')
          },
          products: products
        }
      }
    });
  }
};

export default listener;
