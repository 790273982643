import JsTabs from 'js-tabs';
import Accordion from '../components/accordion';

const update = {
  productDetails: (product, container) => {
    const productID = container.find('#productID'); // for back in stock
    productID.val(product.id);

    container.attr('data-pid', product.id);

    const productName = container.find('.product-name');
    productName.text(product.productName);

    const productNameBrand = container.find('.product-name-brand');
    productNameBrand.text(product.brand);

    const productDescription = container.find('.product-description');
    productDescription.empty().html(product.shortDescription);

    const productAvailability = container.find('.product-availability');
    productAvailability.attr('data-ready-to-order', product.readyToOrder).attr('data-available', product.available);

    const productAvailabilityMsg = container.find('.stock-message');
    const availabilityItems = product.availability.messages;
    if (availabilityItems.length >= 0) {
      productAvailabilityMsg.text(availabilityItems[0].msg).css({ 'background-color': availabilityItems[0].color });
    }

    const $subscriptionPriceContainer = $('.sor-price-container');
    if (!product.multiPack.length) {
      if (product.price.sorPrice.formatted) {
        if ($subscriptionPriceContainer.hasClass('d-none')) {
          $subscriptionPriceContainer.removeClass('d-none');
        }
        const $subscriptionPriceValue = $subscriptionPriceContainer.find('.sor-price-container-value');

        if ($subscriptionPriceValue.length) {
          $subscriptionPriceValue.html(product.price.sorPrice.formatted);
          $subscriptionPriceValue.attr('content', product.price.sorPrice.decimalPrice ? product.price.sorPrice.decimalPrice : '');

          const $mainPrice = $('.details-container .sales .value, .sticky-add-to-cart .sales .value');
          const $savingValue = $('.smart-order-refill__saving-value');
          const savings = parseFloat($mainPrice.attr('content')) - parseFloat($subscriptionPriceValue.attr('content'));
          const currencySymbol = $subscriptionPriceValue.html().replace($subscriptionPriceValue.attr('content').replace('.', ','), '');
          $savingValue.html(`${currencySymbol} ${savings.toFixed(2).replace('.', ',')}`);
        }
      } else {
        $subscriptionPriceContainer.addClass('d-none');
      }
    }

    const detailsDescription = container.find('.description-and-detail');
    detailsDescription.empty().html(product.attributeProductHtml);
    detailsDescription.find('table').each((index, elem) => {
      $(elem).removeAttr('style').addClass('table table-bordered table-striped');
      $(elem).find('tr').removeAttr('style');
      $(elem).find('td').removeAttr('style');
      if (!$(elem).closest().hasClass('table-responsive')) {
        $(elem).wrap('<div class="table-responsive" />');
      }
    });

    if (!product.price.discount) {
      $('.primary-slider').find('.discount').hide();
    } else {
      const discountWrapper = $('.primary-slider').find('.discount');
      const discount = product.price.discount.toFixed(0);

      if (!discountWrapper.length) {
        $('.primary-slider').append(`<div class="discount">-${discount}%</div>`);
      } else {
        discountWrapper.empty().text(`-${discount}%`).show();
      }
    }

    const myTabs = new JsTabs({
      elm: '#detail',
      shouldScrollTabIntoView: false
    });

    myTabs.init();
    new Accordion().init('.accordion');
  },
  productPriceWithMultiPack: (response) => {
    const $priceSelector = $('.details-container .prices .price, .sticky-add-to-cart .prices .price');
    const priceContainer = $priceSelector.closest('.prices');
    const $multiPackCarousel = $('#multi-pack');

    if (response.redPrice) {
      priceContainer.addClass('text-red');
    } else {
      priceContainer.removeClass('text-red');
    }

    if ($multiPackCarousel.length > 0) {
      const $activeCell = $('.multi-pack-items.is-active');
      const $discountPrice = $('.details-container .sales .value, .sticky-add-to-cart .sales .value');

      if ($activeCell.length) {
        const unit = $activeCell.attr('data-unit-content');
        const unitPrice = $activeCell.attr('data-unitprice');
        $discountPrice.empty().attr('content', unit).html(unitPrice);

        const $saving = $('.details-container .discount-value-multipack');
        if ($saving.length > 0) {
          const savingValue =
            parseFloat($('.details-container .strike-through .value').attr('content')) -
            parseFloat($('.details-container .sales .value').attr('content'));
          const finalValue = parseFloat(savingValue).toFixed(2).toString().replace('.', ',');
          $saving.empty().html(finalValue + $multiPackCarousel.attr('data-currency-symbol'));
        }

        const $subscriptionPriceContainer = $('.sor-price-container');
        const $subscriptionPriceValue = $subscriptionPriceContainer.find('.sor-price-container-value');
        const $savingValue = $('.smart-order-refill__saving-value');

        if (response.price.sorPrice.formatted) {
          if ($subscriptionPriceContainer.hasClass('d-none')) {
            $subscriptionPriceContainer.removeClass('d-none');
          }
          const subsPrice = $activeCell.attr('data-unitprice-subscription');
          const subsPriceContent = $activeCell.attr('data-unit-subscription');
          const subsSaving = $activeCell.attr('data-subscription-save');

          if (subsPrice !== $discountPrice.html()) {
            $subscriptionPriceValue.html(subsPrice);
            $subscriptionPriceValue.attr('content', subsPriceContent);
            $savingValue.html(subsSaving);
            // update.setSorCostPerPrice(response, subsPrice);
          }
        } else {
          if (!$subscriptionPriceContainer.hasClass('d-none')) {
            $subscriptionPriceContainer.addClass('d-none');
          }
        }
      } else {
        $priceSelector.replaceWith(response.price.html);
      }

      update.setMultipackCostPerKg(response);
    } else {
      $priceSelector.replaceWith(response.price.html);
    }
  },

  setMultipackCostPerKg: function (productData) {
    if (!document.querySelectorAll('.multi-pack-items.is-active') || !productData) return;

    const costPerKgContainer = document.getElementsByClassName('product-qfis')[0];
    let multiPackCostPerKg = update.calculateMultipackCostPerKg(productData);

    if (!multiPackCostPerKg && multiPackCostPerKg === 0) return;

    const idtQfis = productData['idt-qfis'];
    const idtCumd = productData['idt-cumd'];
    multiPackCostPerKg = `(${multiPackCostPerKg.replace('.', ',')}/${idtCumd})`;

    if (!costPerKgContainer) {
      const priceContainer = document.querySelector('.details-top > .prices');

      if (priceContainer) {
        priceContainer.insertAdjacentHTML(
          'beforeend',
          `<p class="product-qfis ml-2" data-idt-qfis="${idtQfis}" data-idt-cumd="${idtCumd}">${multiPackCostPerKg}</p>`
        );
      }
    } else {
      costPerKgContainer.innerHTML = multiPackCostPerKg;
      costPerKgContainer.setAttribute('data-idt-qfis', idtQfis);
      costPerKgContainer.setAttribute('data-idt-cumd', idtCumd);
    }
  },

  calculateMultipackCostPerKg: function (productData) {
    /**
     * CPkg =  productCPkg - (productCPkg / 100 X multiPackPercentage)
     * CPkg: multipack cost per kg
     * productCPkg: product cost per kg
     * multiPackPercentage: multipack percentage
     */
    if (!productData || (productData && (!productData['idt-qfis'] || !productData.multiPack))) return 0;

    const multiPackArr = productData.multiPack;
    const multiPackItemsTotal = multiPackArr.length;
    const salesPrice = productData.price.sales.value;
    const productCPkg = salesPrice / productData['idt-qfis'];
    let multiPackItem = [];

    for (let index = 0; index < multiPackItemsTotal; index++) {
      multiPackItem = multiPackArr[index].row.filter((item) => item.quantity === productData.selectedQuantity);
    }

    if (!multiPackItem.length) return 0;

    const multiPackPercentage = (((salesPrice - multiPackItem[0].unitPrice) / salesPrice) * 100).toFixed(0);

    return (productCPkg - (productCPkg / 100) * multiPackPercentage).toFixed(2);
  },

  setSorCostPerPrice: function (productData = null, sorPrice = null) {
    const productRefillOptions = $('.product-refill-options');
    if (!productRefillOptions.length) return;

    let subsPrice = sorPrice;
    let productQfis = productData ? productData['idt-qfis'] : null;
    let productCumd = productData ? productData['idt-cumd'] : null;

    if (!subsPrice) {
      const $activeCell = $('.multi-pack-items.is-active');
      if ($activeCell.length) {
        subsPrice = $activeCell.attr('data-unitprice-subscription');
      } else {
        subsPrice = productRefillOptions.data('sor-price');
      }
    }

    if (!productData) {
      const productQfisElement = document.getElementsByClassName('product-qfis')[0];
      if (productQfisElement) {
        productQfis = productQfisElement.getAttribute('data-idt-qfis') || null;
        productCumd = productQfisElement.getAttribute('data-idt-cumd') || null;
      }
    }

    if (!productQfis || !productCumd) return;

    const subsPriceWithoutCurrency = subsPrice.toString().replace(/(\d+),(\d+)[\s\S]*/g, '$1.$2');
    const costPerPrice = `${(subsPriceWithoutCurrency / productQfis).toFixed(2)}/${productCumd}`;
    $('.sor-costPerKg-container-value').html(`(${costPerPrice.replace('.', ',')})`);
  }
};

export default update;
