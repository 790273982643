/* eslint-disable no-console */
import { visibleQuantityProductTile } from '@assets/utils/utils';
const math = require('../utils/math');

export default class CounterClass {
  constructor() {
    this.maxvalue = 200;
    this.increaseClass = '.increase';
    this.decreaseClass = '.decrease';
    this.$inputCounter = $('.input-quantity');
  }

  init() {
    this.increase();
    this.decrease();
    math.default.update(this.$inputCounter, this.maxvalue);
    this.animatable();
  }

  increase() {
    $(document).on('click', this.increaseClass, (event) => {
      event.preventDefault();

      const $this = $(event.currentTarget);
      const counter = $this.siblings('.input-quantity');
      const uuid = $this.parent().find('.input-quantity').data('uuid');
      const item = $(`.input-quantity[data-uuid="${uuid}"]`).not(counter);
      const isPDP = counter.data('pdp');
      const isProductTile = counter.data('tile');
      const itemPDP = $(`.input-quantity[data-pdp="${isPDP}"]`).not(counter);
      const decrement = $this.parent().find('.decrease');
      const decrementItem = item.parent().find('.decrease');
      const decrementItemPid = itemPDP.parent().find('.decrease');
      let value = parseInt(counter.val()) + 1;
      let counterArray;
      const qtyTextElement = $this.siblings('.quantity__expereince');
      const qtyLabelSingleProd = qtyTextElement.data('text-one-product');
      const qtyLabelMultipleProds = qtyTextElement.data('text-multiple-products');

      if ($this.hasClass('disabled')) {
        return;
      }

      if (value < 1 || isNaN(value)) {
        value = 1;
      }

      if (value === 1) {
        decrement.addClass('disabled');

        if (item.length > 0) {
          decrementItem.addClass('disabled');
        }

        if (itemPDP.length > 0) {
          decrementItemPid.addClass('disabled');
        }
      }

      if (value > 1) {
        decrement.removeClass('disabled');

        if (item.length > 0) {
          decrementItem.removeClass('disabled');
        }

        if (itemPDP.length > 0) {
          decrementItemPid.removeClass('disabled');
        }
      }

      if (value === this.maxvalue) {
        $this.addClass('disabled');

        if (item.length > 0) {
          decrementItem.addClass('disabled');
        }

        if (itemPDP.length > 0) {
          decrementItemPid.addClass('disabled');
        }
      }

      if (itemPDP.length > 0) {
        counterArray = $.merge(counter, itemPDP);
      } else {
        counterArray = counter;
      }

      qtyTextElement.empty().text(`${value} ${value > 1 ? qtyLabelMultipleProds : qtyLabelSingleProd}`);

      counter.val(value);
      counter.eq(0).trigger('change');
      if (!isProductTile) math.default.update(counterArray, this.maxvalue);
    });
  }

  decrease() {
    $(document).on('click', this.decreaseClass, (event) => {
      event.preventDefault();

      const $this = $(event.currentTarget);
      const counter = $this.siblings('.input-quantity');
      const uuid = $this.parent().find('.input-quantity').data('uuid');
      const item = $(`.input-quantity[data-uuid="${uuid}"]`).not(counter);
      const isPDP = counter.data('pdp');
      const isProductTile = counter.data('tile');
      const itemPDP = $(`.input-quantity[data-pdp="${isPDP}"]`).not(counter);
      const increment = $this.parent().find('.increase');
      const incrementItem = item.parent().find('.increase');
      const decrementItemPid = itemPDP.parent().find('.increase');
      const maxQty = parseInt(counter.attr('data-max-qty'));
      let value = parseInt(counter.val()) - 1;
      let counterArray;
      const isExperienceCounter = visibleQuantityProductTile() && counter.hasClass('input-quantity__expereince');
      const qtyTextElement = $this.siblings('.quantity__expereince');
      const qtyLabelSingleProd = qtyTextElement.data('text-one-product');
      const qtyLabelMultipleProds = qtyTextElement.data('text-multiple-products');

      if ($this.hasClass('disabled')) {
        return;
      }

      if (value < 1 || isNaN(value)) {
        value = 1;

        if (isExperienceCounter) {
          value = 0;
          counter.val(0);
          $this.addClass('disabled');
        }
      }

      if (value === 1) {
        if (!isExperienceCounter) {
          $this.addClass('disabled');
        }

        counter.val(1);

        if (item.length > 0) {
          incrementItem.addClass('disabled');
        }

        if (itemPDP.length > 0) {
          decrementItemPid.addClass('disabled');
          itemPDP.val(1);
        }
      }

      if (value > 1) {
        counter.val(value);
        itemPDP.val(value);
      }

      if (value < this.maxvalue) {
        increment.removeClass('disabled');

        if (item.length > 0) {
          incrementItem.removeClass('disabled');
        }

        if (itemPDP.length > 0) {
          decrementItemPid.removeClass('disabled');
        }
      }

      if (value > maxQty) {
        counter.val(maxQty);
        itemPDP.val(maxQty);
        incrementItem.addClass('disabled');
        counter.attr('data-value-max', maxQty);
      }

      if (itemPDP.length > 0) {
        counterArray = $.merge(itemPDP, counter);
      } else {
        counterArray = counter;
      }

      qtyTextElement.empty().text(`${value} ${value > 1 ? qtyLabelMultipleProds : qtyLabelSingleProd}`);

      counter.eq(0).trigger('change');

      if (!isProductTile && !isExperienceCounter) math.default.update(counterArray, this.maxvalue);
    });
  }

  animatable() {
    $.fn.isInViewport = function () {
      const elementTop = $(this).offset().top;
      const elementBottom = elementTop + $(this).outerHeight();
      const viewportTop = $(window).scrollTop();
      const viewportBottom = viewportTop + $(window).height();

      return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    const $counters = $('.data-animate-number-value');
    const cb = () => {
      $counters.each(function (index, elem) {
        const $elem = $(elem);
        const animate = () => {
          const speed = $elem.attr('data-animate-speed');
          const value = +$elem.attr('data-animate-number');
          const data = +$elem.text();

          const time = value / speed;
          if (data < value) {
            $elem.text(Math.ceil(data + time)).addClass('completed');
            setTimeout(animate, 1);
          } else {
            $elem.text(value);
          }
        };

        animate();
      });
    };

    $(window).on('scroll', function () {
      if ($counters.length > 0 && $counters.isInViewport() && !$counters.hasClass('completed')) cb();
    });
  }
}
