import Menu from './layout/header/menu';
import MiniCart from './components/minicart';
import Quantity from './components/quantity';
import Search from '@assets/components/search';
import Carousel from './components/carousel';
import ClientSideValidation from '@assets/components/clientSideValidation';
import Spinner from '@assets/components/spinner';
import Tooltip from '@assets/components/toolTip';
import Footer from './layout/footer/footer';
import ScrollTo from './components/scrollTo';
import Newsletter from './layout/footer/newsletter';
import Counter from './components/counters';
import WishlistProductTile from './components/wishlistHeart';
import VideoContent from './components/videoContent';
import ProductSuggestionWizard from './components/wizard';
import Booking from './components/booking';
import HttpReferrerTracking from './HttpReferrerTracking';
import AssetScripts from './components/assetScripts';

document.addEventListener('DOMContentLoaded', (e) => {
  new MiniCart().init();
  new Search().init();
  new Menu().init();
  new Carousel().init();
  new ClientSideValidation().init();
  new Tooltip().init();
  new ScrollTo().init();
  new Newsletter().init();
  new Footer().init();
  new Counter().init();
  new Quantity().init();
  new WishlistProductTile().init();
  new VideoContent().init();
  new ProductSuggestionWizard().init();
  new Booking().init();
  new AssetScripts().init();

  if (!window.HttpReferrerTracking) {
    window.HttpReferrerTracking = new HttpReferrerTracking();
    window.HttpReferrerTracking.init();
  }
});

new Spinner().init();
